import {
  BASE_URL,
  ApiCallUploadMethodFormData,
  ApiCallUploadMethodJson,
  ApiCallGetMethod,
  ApiCallDeleteMethod,
} from "../../constant/base";
import { Cookies } from "react-cookie";
const cook = new Cookies();
export const addVehicle = async (formikValue) => {
  const formData = await new FormData();
  formData.append("how_enter_status", formikValue.how_enter_status);
  formData.append("vin_number", formikValue.vin_number);
  // formData.append("MultiVinFile", formikValue.MultiVinFile);
  return ApiCallUploadMethodFormData(
    "api/vehicle/dealership/mid/add",
    "POST",
    formData
  );
};

export const pdfAuction = async (value) => {
  const formData = await new FormData();
  // formData.append("how_enter_status", value.how_enter_status);
  formData.append("pdf", value.pdf);
  return ApiCallUploadMethodFormData(
    `api/auction/extractInfo/${+value?.how_enter_status - 1}`,
    "POST",
    formData
  );
};
export const pdfAuctionPost = async (value) => {
  // const formData = await new FormData();
  // // formData.append("how_enter_status", value.how_enter_status);
  // formData.append("pdf", value.pdf);
  const body =
    value?.how_enter_status === "2"
      ? {
        vin_number: value?.vin_number,
        how_enter_status: value?.how_enter_status,
        pdf: "",
        odometer: value?.odometer ? value?.odometer : "",
        odometerType: value?.odometerType ? +value?.odometerType : "",
        paymentDate: value?.paymentDate ? value?.paymentDate : "",
        pdfPath: value?.pdfPath ? value?.pdfPath : "",
        postalCode: value?.postalCode ? value?.postalCode : "",
        purchaseDate: value?.purchaseDate ? value?.purchaseDate : "",
        sellerName: value?.sellerName ? value?.sellerName : "",
        invoices: {
          buyFee: [
            value?.invoices?.buyFee[0] ? +value?.invoices?.buyFee[0] : "",
            value?.invoices?.buyFee[1] ? +value?.invoices?.buyFee[1] : "",
          ],
          processingFee: [
            value?.invoices?.processingFee[0]
              ? +value?.invoices?.processingFee[0]
              : "",
            value?.invoices?.processingFee[1]
              ? +value?.invoices?.processingFee[1]
              : "",
          ],
          sellPrice: [
            value?.invoices?.sellPrice[0]
              ? +value?.invoices?.sellPrice[0]
              : "",
            value?.invoices?.sellPrice[1]
              ? +value?.invoices?.sellPrice[1]
              : "",
          ],
          transferFee: [
            value?.invoices?.transferFee[0]
              ? +value?.invoices?.transferFee[0]
              : 0,
            value?.invoices?.transferFee[1]
              ? +value?.invoices?.transferFee[1]
              : 0,
          ],
          transportFee: [
            value?.invoices?.transportFee[0]
              ? +value?.invoices?.transportFee[0]
              : 0,
            value?.invoices?.transportFee[1]
              ? +value?.invoices?.transportFee[1]
              : 0,
          ],
          taxType: value?.invoices?.taxType ? value?.invoices?.taxType : "",
          totalPrice: value?.invoices?.totalPrice
            ? +value?.invoices?.totalPrice
            : "",
          // taxType: value?.invoices?.taxType ? value?.invoices?.taxType : "",
          totalTax: value?.invoices?.totalTax
            ? +value?.invoices?.totalTax
            : "",
        },
      }
      : {
        vin_number: value?.vin_number,
        how_enter_status: value?.how_enter_status,
        pdf: "",
        odometer: value?.odometer ? +value?.odometer : "",
        odometerType: value?.odometerType ? value?.odometerType : "",
        paymentDate: value?.paymentDate ? value?.paymentDate : "",
        pdfPath: value?.pdfPath ? value?.pdfPath : "",
        postalCode: value?.postalCode ? value?.postalCode : "",
        purchaseDate: value?.purchaseDate ? value?.purchaseDate : "",
        sellerName: value?.sellerName ? value?.sellerName : "",
        invoices: {
          history: [
            value?.invoices?.history[0] ? +value?.invoices?.history[0] : "",
            value?.invoices?.history[1] ? +value?.invoices?.history[1] : "",
          ],
          registration: [
            value?.invoices?.registration[0]
              ? +value?.invoices?.registration[0]
              : "",
            value?.invoices?.registration[1]
              ? +value?.invoices?.registration[1]
              : "",
          ],
          sellPrice: [
            value?.invoices?.sellPrice[0]
              ? +value?.invoices?.sellPrice[0]
              : "",
            value?.invoices?.sellPrice[1]
              ? +value?.invoices?.sellPrice[1]
              : "",
          ],
          transaction: [
            value?.invoices?.transaction[0]
              ? +value?.invoices?.transaction[0]
              : "",

            value?.invoices?.transaction[1]
              ? +value?.invoices?.transaction[1]
              : "",
          ],
          transportation: [
            value?.invoices?.transportation[0]
              ? +value?.invoices?.transportation[0]
              : "",
            value?.invoices?.transportation[1]
              ? +value?.invoices?.transportation[1]
              : "",
          ],
          totalPrice: value?.invoices?.totalPrice
            ? +value?.invoices?.totalPrice
            : "",
          taxType: value?.invoices?.taxType ? value?.invoices?.taxType : "",
          totalTax: value?.invoices?.totalTax
            ? +value?.invoices?.totalTax
            : "",
        },
      };
  // value?.how_enter_status === 2
  //   ? (body = {
  //       vin_number: value?.vin_number,
  //       how_enter_status: value?.how_enter_status,
  //       pdf: "",
  //       odometer: value?.odometer ? value?.odometer : "",
  //       odometerType: value?.odometerType ? value?.odometerType : "",
  //       paymentDate: value?.paymentDate ? value?.paymentDate : "",
  //       pdfPath: value?.pdfPath ? value?.pdfPath : "",
  //       postalCode: value?.postalCode ? value?.postalCode : "",
  //       purchaseDate: value?.purchaseDate ? value?.purchaseDate : "",
  //       sellerName: value?.sellerName ? value?.sellerName : "",
  //       invoices: {
  //         buyFee: [
  //           value?.invoices?.buyFee[0].fee
  //             ? value?.invoices?.buyFee[0].fee
  //             : "",

  //           value?.invoices?.buyFee[1].tax
  //             ? value?.invoices?.buyFee[1].tax
  //             : "",
  //         ],
  //         processingFee: [
  //           value?.invoices?.processingFee[0].fee
  //             ? value?.invoices?.processingFee[0].fee
  //             : "",
  //           value?.invoices?.processingFee[1].tax
  //             ? value?.invoices?.processingFee[1].tax
  //             : "",
  //           ,
  //         ],
  //         sellPrice: [
  //           value?.invoices?.sellPrice[0].fee
  //             ? value?.invoices?.sellPrice[0].fee
  //             : "",
  //           value?.invoices?.sellPrice[1].tax
  //             ? value?.invoices?.sellPrice[1].tax
  //             : "",
  //         ],
  //         transferFee: [
  //           value?.invoices?.transferFee[0].fee
  //             ? value?.invoices?.transferFee[0].fee
  //             : "",
  //           value?.invoices?.transferFee[1].tax
  //             ? value?.invoices?.transferFee[1].tax
  //             : "",
  //         ],
  //         transportFee: [
  //           value?.invoices?.transportFee[0].fee
  //             ? value?.invoices?.transportFee[0].fee
  //             : "",
  //           value?.invoices?.transportFee[1].tax
  //             ? value?.invoices?.transportFee[1].tax
  //             : "",
  //         ],
  //         taxType: value?.invoices?.taxType ? value?.invoices?.taxType : "",
  //         totalPrice: value?.invoices?.totalPrice
  //           ? value?.invoices?.totalPrice
  //           : "",
  //         totalTax: value?.invoices?.totalTax ? value?.invoices?.totalTax : "",
  //       },
  //     })
  //   : (body = {

  //     });
  return ApiCallUploadMethodJson(
    `api/auction/manual/${+value?.how_enter_status - 1}`,
    "POST",
    body
  );
};
export const updateVehicle = async (body) => {
  const { value, id } = body;
  return ApiCallUploadMethodJson(
    `api/vehicle/dealership/mid/update/${id}`,
    "PATCH",
    value
  );
};

export const updateCarfaxPdf = async (body) => {
  const { values, id } = body;
  const formData = new FormData();

  if (values?.carfax_pdf && values?.carfax_pdf !== null) {
    formData.append("carfax_pdf", values?.carfax_pdf);
  }
  return ApiCallUploadMethodFormData(
    `api/vehicle/update/carfax-pdf/${id}`, //:vehicleId
    "PATCH",
    formData,
    false
  );
};

export const customizeColumns = async (body) => {
  return ApiCallUploadMethodJson(`user/customized/columns`, "PATCH", body);
};

export const updateVehicleMoreOption = async (body) => {
  const { more_option, id } = body;
  return ApiCallUploadMethodJson(
    `api/vehicle/dealership/mid/update/${id}`,
    "PATCH",
    { more_option: more_option }
  );
};

export const comingSoonVehicle = async (ids) => {
  return ApiCallUploadMethodJson(
    `api/vehicle/dealership/mid/multi/comming_soon`,
    "PATCH",
    { ids: ids }
  );
};
export const leasedVehicle = async ({ ids, inLease }) => {
  return ApiCallUploadMethodJson(
    `api/vehicle/dealership/mid/multi/in_lease`,
    "PATCH",
    { ids: ids, inLease }
  );
};
export const editAuctionPurchase = async (body) => {
  const { value, id } = body;
  return ApiCallUploadMethodJson(`api/auction/manual/${id}`, "PATCH", value);
};
export const addWithoutVinNumber = (body) => {
  return ApiCallUploadMethodJson(
    "api/midvehicle/inventory/no-vin",
    "POST",
    body
  );
};
export const postVehiclesFromExel = (body) => {
  return ApiCallUploadMethodJson(
    "api/vehicle/dealership/vin/list/add",
    "POST",
    body
  );
};
export const postVehiclesAdesa = async (value) => {
  const formData = await new FormData();

  formData.append("csv", value?.csv);

  return ApiCallUploadMethodFormData(
    "api/vehicle/dealership/adesa/auction/add",
    "POST",
    formData
  );
};
export const getSingleVehicleDasdhboard = (id) => {
  return ApiCallGetMethod(`api/vehicle/dealership/mid/inventory/${id}`);
};
export const getTax = (amount) => {
  return ApiCallGetMethod(`calculate/tax/${amount}`);
};
//
export const getColors = () => {
  return ApiCallGetMethod("api/colors");
};
export const getTransmissions = ({ vehicleType }) => {
  return ApiCallGetMethod(`api/transmissions?vehicleType=${vehicleType}`);
};
export const getBodyStyle = ({ vehicleType }) => {
  return ApiCallGetMethod(`api/bodyStyles?vehicleType=${vehicleType}`);
};
export const getTitleStatus = () => {
  return ApiCallGetMethod("api/titlestatus");
};
export const getDriveTime = () => {
  return ApiCallGetMethod("api/drivetype");
};
//
export const toggleReadyVehicle = (id) => {
  return ApiCallUploadMethodJson(
    `api/mid/vehicle/change/status/${id}`,
    "PATCH",
    {}
  );
};
export const deleteVehicle = (id) => {
  return ApiCallDeleteMethod(`api/vehicle/dealership/mid/delete/${id}`);
};
export const deleteVehicles = (ids) => {
  return ApiCallDeleteMethod(
    `api/vehicle/dealership/mid/multi/delete?ids=${ids}`,
    "DELETE"
  );
};
export const GarageReports = (id) => {
  return ApiCallGetMethod(`garage/history/${id}`);
};
export const reverteDeleteVehicles = (ids) => {
  return ApiCallDeleteMethod(
    `api/vehicle/dealership/mid/multi/revert/delete?ids=${ids}`,
    "DELETE"
  );
};
export const changeToSoldVehicle = (ids) => {
  return ApiCallUploadMethodJson(`api/mid/vehicle/change/status`, "PATCH", {
    ids: ids.selectedVehicles,
    status: ids.status,
    sold_date: ids.sold_date,
    // cl_delete: ids.deletecraigslist,
  });
};
export const changeSoldVehicle = (selectfinance) => {
  return ApiCallUploadMethodJson(
    `api/v2/mid/vehicle/change/status`,
    "PATCH",
    selectfinance
  );
};
export const changeToPendingVehicle = (ids) => {
  return ApiCallUploadMethodJson(
    `api/mid/vehicle/change/full/status`,
    "PATCH",
    ids?.is_coming_soon === 0
      ? {
        ids: ids.selectedVehicles,
        vehicle_status: ids.status,
        is_coming_soon: ids.is_coming_soon,
      }
      : {
        ids: ids.selectedVehicles,
        vehicle_status: ids.status,
      }
  );
};
export const changeToCallForPrice = (ids) => {
  return ApiCallUploadMethodJson(
    `api/v1/dealership/call-for-price/${ids?.status}`,
    "PUT",
    { ids: ids?.selectedVehicles }
  );
};
export const changeToComingSoonVehicle = (ids) => {
  return ApiCallUploadMethodJson(
    `api/mid/vehicle/change/full/status`,
    "PATCH",
    {
      ids: ids.selectedVehicles,
      vehicle_status: ids.status,
      is_coming_soon: ids.comin_soon,
    }
  );
};
export const certainAmountVehicle = ({ value }) => {
  return ApiCallUploadMethodJson(`update/mid/vehicles/totally`, "PATCH", value);
};
export const certainAmountAllVehicle = ({ value }) => {
  return ApiCallUploadMethodJson(
    `update/mid/vehicles/totally?selectedAll=1`,
    "PATCH",
    value
  );
};
export const certainAmountCurrentVehicle = ({ value }) => {
  return ApiCallUploadMethodJson(
    `update/mid/ready/for/sale/vehicles/totally?selectedAll=1`,
    "PATCH",
    value
  );
};
export const certainAmountCurrentVehicleWithIds = ({ value }) => {
  return ApiCallUploadMethodJson(
    `update/mid/ready/for/sale/vehicles/totally`,
    "PATCH",
    value
  );
};
export const certainAmountPendingVehicle = ({ value }) => {
  return ApiCallUploadMethodJson(
    `update/mid/pending/vehicles/totally`,
    "PATCH",
    value
  );
};
export const certainAmountComingSoonVehicle = ({ value }) => {
  return ApiCallUploadMethodJson(
    `update/mid/coming/soon/vehicles/totally`,
    "PATCH",
    value
  );
};
export const getSingleInventoryBOSData = (vehicleId) => {
  return ApiCallGetMethod(`api/v1/billofsale/getbill/${vehicleId}`);
};
export const getLeaseAgreement = (vehicleId) => {
  return ApiCallGetMethod(`api/billofsale/lease/agreement/${vehicleId}`);
};
export const getSingleInventoryWholeSaleData = (vehicleId) => {
  return ApiCallGetMethod(`billofsale/insert/wholesalebill/${vehicleId}`);
};
export const getSingleInventoryWholeSaleTax = () => {
  return ApiCallGetMethod(`get/wholesale/tax/selector`);
};
export const getSingleInventoryWholeSaleCustomer = () => {
  return ApiCallGetMethod("wholesale");
};
export const addSingleInventoryWholeSaleCustomer = (body) => {
  return ApiCallUploadMethodJson(`wholesale`, "POST", body);
};
export const UpdateBillOfSaleSingleVehicleData = ({ id, body }) => {
  return ApiCallUploadMethodJson(
    `api/v1/billofsale/updatebill/${id}`,
    "PATCH",
    body
  );
};
export const ResetBillOfSaleSingleVehicleData = ({ id, body }) => {
  return ApiCallUploadMethodJson(
    `api/v1/billofsale/updatebill/${id}?default=1`,
    "PATCH",
    body,
    false
  );
};

export const UpdateBillOfSaleSingleVehiclePayments = (body) => {
  return ApiCallUploadMethodJson(
    `api/v1/bill-of-sale/init-bhph?force=false`,
    "POST",
    body,
    false
  );
};

export const payBHPH = (body) => {
  return ApiCallUploadMethodJson(`api/v1/bill-of-sale/pay-bhph`, "PUT", body);
};

export const UndoPayBHPH = (id) => {
  return ApiCallUploadMethodJson(`api/v1/bill-of-sale/bhph/unpay`, "PATCH", {
    bhphId: id,
  });
};

export const EditPayBHPH = (body) => {
  return ApiCallUploadMethodJson(
    `api/v1/bill-of-sale/bhph/force-modify`,
    "PATCH",
    body
  );
};

export const getBHPH = (bosId) => {
  return ApiCallGetMethod(`api/v1/bill-of-sale/bhph/${bosId}`);
};

export const DeleteBHPH = (id) => {
  return ApiCallDeleteMethod(`api/v1/bill-of-sale/bhph/${id}`, "DELETE");
};

export const UpdateLeasAgreement = ({ id, body }) => {
  return ApiCallUploadMethodJson(
    `api/billofsale/lease/agreement/${id}`,
    "PATCH",
    body
  );
};
export const UpdateBillOfSaleSingleVehicleBosType = ({ id, bos_type }) => {
  return ApiCallUploadMethodJson(
    `change/bill/of/sale/type/${id}`,
    "PATCH",
    {
      bos_type,
    },
    false
  );
};
export const AddTradeInNego = (body) => {
  return ApiCallUploadMethodJson(
    `api/negotiate/trade/vehicle/bos/manually`,
    "POST",
    body
  );
};
export const DeleteTradeInNego = (body) => {
  return ApiCallDeleteMethod(
    `api/negotiate/trade/vehicle/bos/manually/${body}`,
    "DELETE"
  );
};

export const DeleteBos = (body) => {
  return ApiCallUploadMethodJson(`reset/bos/calculator/${body}`, "PATCH", {});
};

export const ChangeVersionOfBos = (body) => {
  return ApiCallUploadMethodJson(`reset/bos/calculator/${body?.id}?forceVersion=${body?.version}`, "PATCH", {});
};

export const DeleteBhph = (body) => {
  return ApiCallDeleteMethod(
    `api/v1/bill-of-sale/bhph/purge/${body}`,
    "DELETE"
  );
};
export const AddTradeInLeaseAgreement = (body) => {
  return ApiCallUploadMethodJson(
    `api/negotiate/trade/vehicle/bos/manually?lease=1`,
    "POST",
    body
  );
};
export const DeleteTradeInLeaseAgreement = (body) => {
  return ApiCallDeleteMethod(
    `api/negotiate/trade/vehicle/bos/manually/${body}?lease=1`,
    "DELETE"
  );
};
export const DeleteSholeSaleSingleVehicleData = (id) => {
  return ApiCallDeleteMethod(`wholesale/${id?.deletdted}`, "DELETE");
};
export const EditSholeSaleSingleVehicleData = ({ id, value }) => {
  return ApiCallUploadMethodJson(`wholesale/${id}`, "PATCH", value);
};
export const UpdateWholefSaleSingleVehicleData = ({ id, body }) => {
  return ApiCallUploadMethodJson(
    `v2/billofsale/update/wholesalebill/${id}`,
    "PATCH",
    body
  );
};
export const getSingleInventoryBOSFormPDF = async (tradeIn) => {
  const token = cook.get("token");
  const path = `api/billofsale/buffer/file?${tradeIn
    ? "tradeIn=2&fromOldDealInventory=1"
    : "tradeIn=1&fromOldDealInventory=1"
    }`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};
export const getSingleInventoryBOSFormPDFDeal = async (tradeIn) => {
  const token = cook.get("token");
  const path = `api/billofsale/buffer/file?${tradeIn ? "tradeIn=2" : "tradeIn=1"
    }`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};
export const getSingleInventoryManualBOSFormPDF = async (tradeIn) => {
  const token = cook.get("token");
  const path = `api/billofsale/buffer/file?${tradeIn?.disabledTradeIn ? "tradeIn=2" : "tradeIn=1"
    }&manual=1&version=${tradeIn?.pdfVersion}`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};
export const getSingleInventoryLeaseAgreementFormPDF = async (tradeIn) => {
  const token = cook.get("token");
  const path = `api/billofsale/buffer/file?${tradeIn ? "tradeIn=2" : "tradeIn=1"
    }&lease=1`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};
export const getSingleInventoryWholeSaleFormPDF = async (tradeIn) => {
  const token = cook.get("token");
  const path = `wholesale/populate/pdf`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};
export const getSingleDealDeliveryFormPDF = async () => {
  const token = cook.get("token");
  const path = `api/billofsale/delivery/buffer/file`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};

export const getSingleDepositFormPDF = async () => {
  const token = cook.get("token");
  const path = `api/deposit/form/buffer/file`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};
export const getSingleWarrantyFormPDF = async () => {
  const token = cook.get("token");
  const path = `api/warranty/form/buffer/file`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};

export const sendVehicleToFacebook = ({ id, token, userId, url }) => {
  return ApiCallGetMethod(
    `api/test/facebook/auth?id=${id}&token=${token}&user_id=${userId}&url=${url}`
  );
};
export const getCaringLiastCities = () => {
  return ApiCallGetMethod("api/get/all/craigslists/cities");
};
export const getCaringlistSubCity = (id) => {
  return ApiCallGetMethod(`sub/city/get/${id}`);
};
export const sendVehiclesListToCaringList = ({
  selectedVehicles,
  cityId,
  subCity,
}) => {
  const body = {
    vehicles: selectedVehicles,
    cityId,
    subcityId: subCity,
  };
  return ApiCallUploadMethodJson(
    "api/add/mid/vehicles/to/craigslist",
    "POST",
    body
  );
};
export const sendVehiclesListToFaceBook = ({ selectedVehicles }) => {
  const body = {
    vehicles: selectedVehicles,
    // cityId,
  };
  return ApiCallUploadMethodJson(
    "add/vehicles/facebook/marketplace",
    "POST",
    body
  );
};
//Vehicle Detail Route//
export const getVehicleDetail = (vehicleId) => {
  return ApiCallGetMethod(`api/mid/vehicle/full/detail/${vehicleId} `);
};

//Customer detail//
export const getCustomerDetailPurchese = (CustomerId) => {
  return ApiCallGetMethod(
    `api/dealerweb/market/get/customer/info/${CustomerId} `
  );
};

//SoldBillOf//
export const SoldBillOf = async ({ mvdBosId, body }) => {
  return ApiCallUploadMethodJson(
    `api/mid/vehicle/bos/mark/sold/${mvdBosId}`,
    "PATCH",
    body
  );
};
//UpdateSoldBillOf//
export const UpdateSoldBillOf = async ({ mvdBosId, body }) => {
  return ApiCallUploadMethodJson(
    `api/mid/vehicle/bos/modify/sold/${mvdBosId}`,
    "PATCH",
    body
  );
};
//Vehicle Types//
export const getVehicleTypes = () => {
  return ApiCallGetMethod(`vehicle/type`);
};

export const getSingleInventoryAppraisalFormPDF = async () => {
  const token = cook.get("token");
  const path = `api/billofsale/buffer/file?appraisal=1`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};

export const getApprisalInventoryFormPDF = async () => {
  const token = cook.get("token");
  const path = `api/billofsale/buffer/file?customer_appraisal=1`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};

export const getPurchesFromPublicFormPDF = async () => {
  const token = cook.get("token");
  const path = `api/billofsale/buffer/file?step_pdf=1`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};

export const getPurchaseFromPurchaseFormPDF = async () => {
  const token = cook.get("token");
  const path = `api/billofsale/buffer/file?wholesale_purchase=1`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};

export const updatePurcheseFromPublic = async ({ body, id }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/market/modify/${id}`,
    "PATCH",
    body
  );
};

export const updatePurchaseFromWholeSale = async ({ body, id }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/market/wholesale/modify/${id}`,
    "PATCH",
    body
  );
};

export const moveVehiclTo = ({ body }) => {
  return ApiCallUploadMethodJson(
    `switch/inventory/to/other/branch`,
    "PATCH",
    body
  );
};
export const changeVehiclStatusUc = ({ body }) => {
  return ApiCallUploadMethodJson(`mid/vehicle/extra/status`, "PATCH", body);
};

export const faceBookPost = ({ id, body }) => {
  return ApiCallUploadMethodJson(
    `api/facebook/post-to-page/${id}`,
    "POST",
    body
  );
};

export const faceBookLogInLink = () => {
  return ApiCallGetMethod(`api/facebook/login_link`);
};

export const faceBookPages = () => {
  return ApiCallGetMethod(`api/facebook/login_status`);
};

export const getChequePDF = async () => {
  const token = cook.get("token");
  const path = `cheque/pdf/buffer`;
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return _res;
};


//Mechanic Shop//
export const addVinNumberMechanicShop = (body) => {
  return ApiCallUploadMethodJson("vehicle/mechanic/shop   ", "POST", body);
};
export const addVinNumberMechanicShopSold = (body) => {
  return ApiCallUploadMethodJson(
    "vehicle/mechanic/shop?onlyShop=1",
    "POST",
    body
  );
};
export const addVinNumberMechanicShopNO = (body) => {
  return ApiCallUploadMethodJson(
    "vehicle/mechanic/shop?inventory=1",
    "POST",
    body
  );
};
export const getVehicleMechanicShop = () => {
  return ApiCallGetMethod(`vehicle/mechanic/shop`);
};

// export const updateMechanicShop = async ({ id, value }) => {
//   console.log({ id });
//   return ApiCallUploadMethodJson(`vehicle/mechanic/shop/${id}`, "PATCH", value);
// };

export const updateMechanicShop = (id, body) => {
  return ApiCallUploadMethodJson(`vehicle/mechanic/shop/${id}`, "PATCH", body);
};

export const updateMechanicShopwhithQury = (id, body) => {
  return ApiCallUploadMethodJson(
    `vehicle/mechanic/shop/${id}?tradeIn=1`,
    "PATCH",
    body
  );
};

export const getServiceOption = async () => {
  return ApiCallGetMethod("vehicle/mechanic/service/status  ");
};
export const getPaymenType = async () => {
  return ApiCallGetMethod("invoice/payment/type ");
};
export const getPaymenStatus = async () => {
  return ApiCallGetMethod("invoice/payment/status");
};

export const getInvetoryListForMechnic = () => {
  return ApiCallGetMethod(`inventory/list/for/mechanic/service`);
};

export const entryOdometerMechanicShop = (body) => {

  return ApiCallUploadMethodJson(
    `initialize/vehicle/mechanic/shop  `,
    "PATCH",
    body
  );
};

//window sticker all//
export const windowstickerDetailedPost = (ids) => {
  return ApiCallUploadMethodJson(
    `api/vehicle/list`,
    "POST",
    { ids: ids }, false

  );
};