import React, { useEffect } from "react"; // useState, // useEffect,
import { ToastContainer } from "react-toastify";
import AdminDrawer from "./components/layout/admin_drawer";
import AdminNavbar from "./components/layout/admin_navbar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AdminRouter from "./components/routes/admin_routes";
import DealerSignin from "./components/signin/dealer_signin_admin";
// import { connect } from "react-redux";
// import { userSelect } from "./redux/select/auth";
import { QueryClient, QueryClientProvider } from "react-query";
// import { Cookies } from "react-cookie";
// import { isValidToken } from "./utils/token/check_token";
// import PrivateRoute from "./components/routes/private_route";
import {
  // useIsAuthDispatch,
  useIsAuthState,
} from "./hooks/useAuth";
import PublicRoute from "./components/routes/public_route";
import Confirm from "./components/web/confirm/confirm";
// import { useSetUserDispatch, useUser } from "./context/user";
// import { checkCookieIsEnabled } from "./utils/auth/check_navigator_cookie";
import ContractProvider from "./context/contract";
import DrawerContextProvider from "./context/darwer";
import SellerRoute from "./components/routes/seller_routes";
import CustomerRouter from "./components/routes/customer_routes";
import BasketProvider from "./context/basket";
import ScrollToTop from "./components/routes/scroll-to-top-onmount/scroll_to_top_on_mount";
import CustomerDrawer from "./components/layout/customer_drawer";
import SellerDrawer from "./components/layout/seller_drawer";
import HillzRoute from "./components/routes/hillz_routes";
import HillzDrawer from "./components/layout/hillz_drawer";
import SocketProvider from "./context/socket";
import Agreement from "./components/agreements/agreement";
import ServiceDrawer from "./components/layout/service_drawer";
import ServiceRoutes from "./components/routes/service_routes";
import InventoryVehicle from "./context/inventory";
import DealInventoryVehicle from "./context/dealInventory";
import LeadContext from "./context/leadContext";
import { apiOffline } from "./utils/navbar/navbar.utils";
import { Cookies } from "react-cookie";
import QuickBooksContext from "./context/quickBooksContext";
import OperatoreDrawer from "./components/layout/operatore_drawer";
import OperatoreRoutes from "./components/routes/operatore_routes";
// const cook = new Cookies();

export const appQueryClient = new QueryClient();
function App(props) {
  const auth = useIsAuthState();
  const containereNotifRef = React.useRef();

  const cookie = new Cookies();

  const unloadHandler = async (e) => {
    // e.preventDefault()
    if (cookie.get("token")) {
      await apiOffline();
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", unloadHandler);

    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
    };
  }, []);

  // const setAuth = useIsAuthDispatch();
  const closeNotificationBox = () =>
    containereNotifRef?.current?.closeNotificationBox();
  return (
    <div onClick={closeNotificationBox}>
      <ToastContainer position="top-center" />
      <QueryClientProvider client={appQueryClient}>
        <InventoryVehicle>
          <DealInventoryVehicle>
            <QuickBooksContext>
              <LeadContext>
                <SocketProvider>
                  <Router basename="/#">
                    <ScrollToTop />
                    <Switch>
                      <Route exact path="/signin" component={DealerSignin} />
                      <Route
                        exact
                        path={`/agreement/*`}
                        component={Agreement}
                      />
                      <PublicRoute
                        exact
                        path="/reset-password/:token"
                        component={Confirm}
                      />
                      <ContractProvider>
                        <BasketProvider>
                          <DrawerContextProvider>
                            <div className="admin-container">
                              <AdminNavbar
                                {...props}
                                ref={containereNotifRef}
                              />
                              {auth.isLoggedIn &&
                                (auth.user.frk_role === 3 ||
                                  auth.user.frk_role === 19 ||
                                  auth.user.frk_role === 20 ||
                                  auth.user.frk_role === 15 ||
                                  auth.user.frk_role === 22
                                ) &&
                                auth.ds_status !== 3 &&
                                auth?.ds_status !== 4 &&
                                auth?.ds_status !== 7 &&
                                auth?.ds_status !== 8 &&
                                auth?.ds_status !== 9 ? (
                                <AdminDrawer {...props} />
                              ) : auth.isLoggedIn &&
                                auth.user.frk_role === 2 ? (
                                <CustomerDrawer {...props} />
                              ) : auth.isLoggedIn &&
                                auth.user.frk_role === 5 ? (
                                <ServiceDrawer {...props} />
                              ) : auth.isLoggedIn &&
                                auth.user.frk_role === 17 ? (
                                <OperatoreDrawer {...props} />
                              ) : (auth.isLoggedIn &&
                                auth.user.frk_role === 6) ||
                                (auth.isLoggedIn &&
                                  auth.user.frk_role === 14) ||
                                (auth.isLoggedIn &&
                                  auth.user.frk_role === 16) ||
                                (auth.isLoggedIn &&
                                  auth.user.frk_role === 18)
                                ? (
                                  <SellerDrawer {...props} />
                                ) : auth.isLoggedIn &&
                                  auth.user.frk_role === 1 ? (
                                  <HillzDrawer {...props} />
                                ) : null}
                            </div>
                            {auth.isLoggedIn &&
                              (auth.user.frk_role === 3 ||
                                auth.user.frk_role === 19 ||
                                auth.user.frk_role === 20 ||
                                auth.user.frk_role === 15 ||
                                auth.user.frk_role === 22
                              ) ? (
                              <AdminRouter />
                            ) : auth.isLoggedIn && auth.user.frk_role === 2 ? (
                              <CustomerRouter />
                            ) : auth.isLoggedIn && auth.user.frk_role === 5 ? (
                              <ServiceRoutes />
                            ) : auth.isLoggedIn && auth.user.frk_role === 17 ? (
                              <OperatoreRoutes />
                            ) : (auth.isLoggedIn && auth.user.frk_role === 6) ||
                              (auth.isLoggedIn && auth.user.frk_role === 14) ||
                              (auth.isLoggedIn && auth.user.frk_role === 16) ||
                              (auth.isLoggedIn && auth.user.frk_role === 18)
                              ? (
                                <SellerRoute />
                              ) : auth.isLoggedIn && auth.user.frk_role === 1 ? (
                                <HillzRoute />
                              ) : (
                                <Redirect to="/signin" />
                              )}
                          </DrawerContextProvider>
                        </BasketProvider>
                      </ContractProvider>
                    </Switch>
                  </Router>
                </SocketProvider>
              </LeadContext>
            </QuickBooksContext>
          </DealInventoryVehicle>
        </InventoryVehicle>
      </QueryClientProvider>
    </div>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     user: userSelect(state),
//   };
// };
// export default connect(mapStateToProps)(App);
export default App;
