/* eslint-disable no-unreachable */
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import { checkRefreshToken } from "../utils/auth/checkRefreshToken";
const cook = new Cookies();

export const BASE_URL = "https://api.hillzusers.com/";
export const WEBSITE_BASE_URL = "https://api.hillzusers.com";

export const QUICKBOOKS_BASE_URL = "https://quickbooks.test222web.ca/";

export const SHOP_BASE_URL = "https://shop.test222web.ca/";
// export const SHOP_BASE_URL = "http://192.168.1.181:5010/";

// export const BASE_URL = "http://192.168.1.150:5001/";
// export const WEBSITE_BASE_URL = "http://192.168.1.150:5001";

// export const BASE_URL = "https://api.test333web.ca/";
// export const WEBSITE_BASE_URL = "https://api.test333web.ca";

// export const BASE_URL = "https://test111web.ca/";
// export const WEBSITE_BASE_URL = "https://test111web.ca";

// export const BASE_URL = "http://192.168.1.150:5000/";
// export const WEBSITE_BASE_URL = "http://192.168.1.150:5000";

// export const BASE_URL = "http://192.168.1.155:5000/";
// export const WEBSITE_BASE_URL = "http://192.168.1.155:5000";

// export const BASE_URL = "http://192.168.1.194:5000/";
// export const WEBSITE_BASE_URL = "http://192.168.1.194:5000";

// export const QUICKBOOKS_BASE_URL = "http://192.168.1.149:8000/";
export const FACEBOOK_APP_ID = "638542857434659";
export const IMAGE_CDN_DOMAIN = "https://hillzimage.blob.core.windows.net";
export const MAX_AGE_COOKIE = 10800;
export const CARD_ID = [
  { id: 0, name: "visa_card" },
  { id: 1, name: "american_card" },
  { id: 2, name: "master_card" },
];
export const ApiCallGetMethod = async (path) => {
  const token = cook.get("token");
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (_res.status === 400) {
    toast.error("Error " + _res?.error?.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 401) {
    checkRefreshToken();
    throw new Error("UnAthorize");
    return;
    //some get redirect
  } else if (_res.status === 403) {
    let data = await _res.json();
    toast.warning("" + data.message);
    throw new Error("" + _res?.error?.message);
  } else if (_res.status === 404) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 405) {
    //not allow
    let data = await _res.json();
    toast.error("Error " + data.message);
    return data;
    // throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 500) {
    toast.error("something went wrong");
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.ok && _res.status === 200) {
    cook.set("token", token, {
      maxAge: MAX_AGE_COOKIE,
    });
    const data = await _res.json();
    return data;
  } else if (_res.ok && _res.status === 207) {
    const data = await _res.json();
    return data;
  } else if (_res.ok && _res.status === 206) {
    const data = await _res.json();
    return data;
  }
};
export const ApiCallGetMethodQuickBooks = async (path, showNote = true) => {
  const token = cook.get("token");
  let _res = await fetch(`${QUICKBOOKS_BASE_URL}${path}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (_res.status >= 400) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status < 300) {
    try {
      let data = await _res.json();
      if (showNote) toast.success(data?.message);
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  }
};
export const ApiCallUploadMethodJson = async (
  path,
  method,
  body,
  showNote = true,
  notMessage,
  keepalive = false
) => {
  const cook = new Cookies();
  const token = cook.get("token");
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: method,
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    keepalive,
  });
  if (_res.status === 400) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 401) {
    checkRefreshToken();
    throw new Error("UnAthorize");
    //some get redirect
  } else if (_res.status === 403) {
    let data = await _res.json();
    toast.warning("" + data.message);
    throw new Error("" + _res?.error?.message);
  } else if (_res.status === 404) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 402) {
    toast.error("unsuccessful pay");
  } else if (_res.status === 500) {
    toast.error("Server Error");
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 201) {
    if (showNote) {
      toast.success("Done");
    }
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  } else if (_res.status === 200) {
    if (showNote) {
      toast.success("Done");
      // toast.success(_res?.statusText);
    }
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  } else if (_res.status === 207) {
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  }
};
export const ApiCallUploadMethodJsonQuickBooks = async (
  path,
  method,
  body,
  showNote = true,
  notMessage,
  keepalive = false
) => {
  const cook = new Cookies();
  const token = cook.get("token");
  let _res = await fetch(`${QUICKBOOKS_BASE_URL}${path}`, {
    method: method,
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    keepalive,
  });
  if (_res.status >= 400) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status < 300) {
    try {
      let data = await _res.json();
      if (showNote) toast.success(data?.message);
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  }
};
export const ApiCallUploadMethodFormData = async (
  path,
  method,
  formData,
  showNote = true,
  notMessage
) => {
  const cook = new Cookies();
  const token = cook.get("token");
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: method,
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (_res.status === 400) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 401) {
    //some get redirect
  } else if (_res.status === 403) {
    let data = await _res.json();
    toast.warning("" + data.message);
    throw new Error("" + _res?.error?.message);
  } else if (_res.status === 404) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 500) {
    toast.error("Server Error");
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 201) {
    if (showNote) {
      toast.success("Done");
    }
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  } else if (_res.status === 200) {
    if (showNote) {
      toast.success("Done");
    }
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  }
};
export const ApiCallUploadMethodFormDataQuickBooks = async (
  path,
  method,
  formData,
  showNote = true,
  notMessage
) => {
  const cook = new Cookies();
  const token = cook.get("token");
  let _res = await fetch(`${QUICKBOOKS_BASE_URL}${path}`, {
    method: method,
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (_res.status === 400) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 401) {
    //some get redirect
  } else if (_res.status === 403) {
    let data = await _res.json();
    toast.warning("" + data.message);
    throw new Error("" + _res?.error?.message);
  } else if (_res.status === 404) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 500) {
    toast.error("Server Error");
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 201) {
    if (showNote) {
      toast.success("Done");
    }
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  } else if (_res.status === 200) {
    if (showNote) {
      toast.success("Done");
    }
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  }
};
export const ApiCallDeleteMethod = async (path) => {
  const cook = new Cookies();
  const token = cook.get("token");
  let _res = await fetch(`${BASE_URL}${path}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (_res.status === 400) {
    toast.error("Error " + _res?.error?.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 401) {
    //some get redirect
  } else if (_res.status === 403) {
    let data = await _res.json();
    toast.warning("" + data.message);
    throw new Error("" + _res?.error?.message);
  } else if (_res.status === 404) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 500) {
    toast.error("Server Error");
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 409) {
    let data = await _res.json();
    toast.error(`${data?.message}`);
    throw new Error("Error " + data?.message);
    // return _res;
  } else if (_res.ok && _res.status === 201) {
    toast.success("Done");
    // return _res;
  } else if (_res.ok && _res.status === 200) {
    toast.success("Done");
    // return _res;
  }
};
export const ApiCallGetMethodForShop = async (path) => {
  const token = cook.get("token");
  let _res = await fetch(`${SHOP_BASE_URL}${path}`, {
    method: "GET",
    // credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      // 'Origin': '*',
    },
    // mode: 'cors', // Set the mode to 'cors'
  });
  if (_res.status === 400) {
    toast.error("Error " + _res?.error?.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 401) {
    checkRefreshToken();
    throw new Error("UnAthorize");
    return;
    //some get redirect
  } else if (_res.status === 403) {
    let data = await _res.json();
    toast.warning("" + data.message);
    throw new Error("" + _res?.error?.message);
  } else if (_res.status === 404) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 405) {
    //not allow
    let data = await _res.json();
    toast.error("Error " + data.message);
    return data;
    // throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 500) {
    toast.error("something went wrong");
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.ok && _res.status === 200) {
    cook.set("token", token, {
      maxAge: MAX_AGE_COOKIE,
    });
    const data = await _res.json();
    return data;
  } else if (_res.ok && _res.status === 207) {
    const data = await _res.json();
    return data;
  } else if (_res.ok && _res.status === 206) {
    const data = await _res.json();
    return data;
  }
};
export const ApiCallUploadMethodJsonForShop = async (
  path,
  method,
  body,
  showNote = true,
  notMessage,
  keepalive = false
) => {
  const cook = new Cookies();
  const token = cook.get("token");
  let _res = await fetch(`${SHOP_BASE_URL}${path}`, {
    method: method,
    // credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    // mode: 'cors', // Set the mode to 'cors'
    body: JSON.stringify(body),
    // keepalive
  });
  if (_res.status === 400) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 401) {
    checkRefreshToken();
    throw new Error("UnAthorize");
    //some get redirect
  } else if (_res.status === 403) {
    let data = await _res.json();
    toast.warning("" + data.message);
    throw new Error("" + _res?.error?.message);
  } else if (_res.status === 404) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 402) {
    toast.error("unsuccessful pay");
  } else if (_res.status === 500) {
    toast.error("Server Error");
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 201) {
    if (showNote) {
      toast.success("Done");
    }
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  } else if (_res.status === 200) {
    if (showNote) {
      toast.success("Done");
      // toast.success(_res?.statusText);
    }
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  } else if (_res.status === 207) {
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  }
};
export const ApiCallUploadMethodFormDataForShop = async (
  path,
  method,
  formData,
  showNote = true,
  notMessage
) => {
  const cook = new Cookies();
  const token = cook.get("token");
  let _res = await fetch(`${SHOP_BASE_URL}${path}`, {
    method: method,
    // credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (_res.status === 400) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 401) {
    //some get redirect
  } else if (_res.status === 403) {
    let data = await _res.json();
    toast.warning("" + data.message);
    throw new Error("" + _res?.error?.message);
  } else if (_res.status === 404) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 500) {
    toast.error("Server Error");
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 201) {
    if (showNote) {
      toast.success("Done");
    }
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  } else if (_res.status === 200) {
    if (showNote) {
      toast.success("Done");
    }
    try {
      let data = await _res.json();
      return data;
    } catch (error) {
      console.log(error, "error ");
      return {};
    }
  }
};
export const ApiCallDeleteMethodQuickBooks = async (path) => {
  const cook = new Cookies();
  const token = cook.get("token");
  let _res = await fetch(`${QUICKBOOKS_BASE_URL}${path}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (_res.status === 400) {
    toast.error("Error " + _res?.error?.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 401) {
    //some get redirect
  } else if (_res.status === 403) {
    let data = await _res.json();
    toast.warning("" + data.message);
    throw new Error("" + _res?.error?.message);
  } else if (_res.status === 404) {
    let data = await _res.json();
    toast.error("Error " + data.message);
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 500) {
    toast.error("Server Error");
    throw new Error("Error " + _res?.error?.message);
  } else if (_res.status === 409) {
    let data = await _res.json();
    toast.error(`${data?.message}`);
    throw new Error("Error " + data?.message);
    // return _res;
  } else if (_res.ok && _res.status === 201) {
    toast.success("Done");
    // return _res;
  } else if (_res.ok && _res.status === 200) {
    toast.success("Done");
    // return _res;
  }
};
export const TICKET_STATUS_LISTS = [
  { value: 1, label: "right now created" },
  { value: 2, label: "seen by admin" },
  { value: 3, label: "Answered" },
  { value: 4, label: "seen by customer" },
  { value: 5, label: "replied by customer " },
  { value: 6, label: "closed" },
];
export const TICKET_VALID_FILE_FORMAT = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];
export const TICKET_REPLY_INITAIL_VALUE = {
  replayMessage: "",
  attachFile: [],
};

export const VEHICLE_CONDITION_LIST = [
  { value: "1", label: "New" },
  { value: "2", label: "As New" },
  { value: "3", label: "Excelent" },
  { value: "4", label: "Good" },
  { value: "5", label: "Fair" },
  { value: "6", label: "Salvage" },
];
export const SELLER_ALL_CARS_FILTER_DATA = (isDealership, sellers) => {
  if (isDealership) {
    return [
      {
        categoryName: "Seller",
        categoryItem: [
          {
            fieldLable: "Seller",
            fieldName: "id",
            fieldType: "react-select",
            fieldOptions: sellers.map((item) => {
              return { value: item?.id, label: item?.f_name };
            }),
          },
        ],
      },
      {
        categoryName: "General Deal",
        categoryItem: [
          {
            fieldType: "select",
            fieldName: "lead_type",
            fieldLable: "Lead Type",
            fieldOptions: [
              { value: "", label: "Select lead type" },
              { value: "1", label: "Value Your Trade" },
              { value: "2", label: "Car Finder" },
              { value: "3", label: "Dealer Web Finances" },
              { value: "4", label: "Book Appointment" },
              { value: "5", label: "Contact us" },
              { value: "6", label: "Service Appointment" },
              { value: "7", label: "Order part(s)" },
              { value: "8", label: "E-Test Drive" },
              { value: "9", label: "Admin Lead" },
              { value: "10", label: "Test Drive" },
            ],
          },
          {
            fieldType: "select",
            fieldName: "negotiate_status",
            fieldLable: "Negotiate Status",
            fieldOptions: [
              { value: "", label: "Select negotiate status" },
              // { value: "-1", label: "Not Seen" },
              // { value: "0", label: "Seen" },
              { value: "1", label: "Qualified" },
              { value: "2", label: "Contact Made" },
              { value: "3", label: "Proposal(s)" },
              { value: "4", label: "Won" },
              { value: "5", label: "Lost" },
            ],
          },
          {
            fieldType: "select",
            fieldLable: "Customer Type",
            fieldName: "customer_type",
            fieldOptions: [
              { value: "", label: "Select Customer type" },
              { value: "1", label: "Person" },
              { value: "2", label: "Business" },
            ],
          },
          {
            fieldType: "number",
            fieldLable: "Success Forcast",
            fieldName: "success_forcast",
          },
          {
            fieldType: "text",
            fieldLable: "Dealership Comment",
            fieldName: "dealership_comment",
          },
        ],
      },
      {
        categoryName: "Vehicle on Deal",
        categoryItem: [
          // {
          //   fieldType: "select",
          //   fieldLable: "Dealership Opinion",
          //   fieldName: "dealership_opinion",
          //   fieldOptions: [
          //     { value: "1", label: "Pending" },
          //     { value: "2", label: "Accept" },
          //     { value: "3", label: "Reject" },
          //   ],
          // },
          {
            fieldName: "car_on_negotiate_status",
            fieldLable: "Car On Deal status",
            fieldType: "select",
            fieldOptions: [
              { value: "", label: "Select car on Deal" },
              { value: "1", label: "Negotiation Started" },
              { value: "2", label: "Bill Of Sales" },
              { value: "3", label: "Down Payment" },
              { value: "4", label: "Finances" },
              { value: "5", label: "Paid off" },
            ],
          },
          {
            fieldType: "number",
            fieldLable: "Success Forcaast",
            fieldName: "mid_success_forcast",
          },
        ],
      },
      {
        categoryName: "Inventory Advance",
        categoryItem: [
          {
            fieldName: "odometer",
            fieldLable: "Odometer",
            fieldType: "number",
          },
          {
            fieldName: "odometer_type",
            fieldLable: "Odometer Type",
            fieldType: "select",
            fieldOptions: [
              { value: "", label: "Select odometer type" },
              { value: "1", label: "Miles" },
              { value: "2", label: "Km" },
            ],
          },
          {
            fieldName: "vehicle_condition",
            fieldLable: "Vehicle Condition",
            fieldType: "select",
            fieldOptions: [
              { value: "", label: "Select Vehicle Condition" },
              { value: "1", label: "New" },
              { value: "2", label: "As New" },
              { value: "3", label: "Excelent" },
              { value: "4", label: "Good" },
              { value: "5", label: "Fair" },
              { value: "6", label: "Salvage" },
            ],
          },
          // {
          //   fieldName: "is_it_special",
          //   fieldLable: "Special",
          //   fieldType: "select",
          //   fieldOptions: [
          //     { value: "1", label: "to show in special cars show" },
          //     { value: "2", label: "not show" },
          //   ],
          // },
          {
            fieldName: "sell_price",
            fieldLable: "Sell Price",
            fieldType: "number",
          },
          {
            fieldName: "special_price",
            fieldLable: "Special Price",
            fieldType: "number",
          },
          {
            fieldName: "vehicle_status",
            fieldLable: "Vehicle Status",
            fieldType: "select",
            fieldOptions: [
              { value: "", label: "Select Vehicle Status" },
              { value: "1", label: "Just Entered" },
              { value: "2", label: "Need Service" },
              { value: "3", label: "At Service" },
              { value: "4", label: "Ready ForSale" },
              { value: "5", label: "Negotiation Started" },
              { value: "6", label: "Bill Of Sales" },
              { value: "7", label: "Down Payment" },
              { value: "8", label: "Sold" },
            ],
          },
        ],
      },
      {
        categoryName: "Inventory basic",
        fieldLable: "Inventory Basic",
        categoryItem: [
          {
            fieldName: "vin_number",
            fieldType: "text",
            fieldLable: "Vin Number",
          },
          {
            fieldName: "make",
            fieldType: "text",
            fieldLable: "Make",
          },
          {
            fieldName: "model",
            fieldType: "text",
            fieldLable: "Model",
          },
          {
            fieldName: "model_year",
            fieldType: "number",
            fieldLable: "Model Year",
          },
          {
            fieldName: "body_style",
            fieldType: "text",
            fieldLable: "Body Style",
          },
          {
            fieldName: "drive_type",
            fieldType: "text",
            fieldLable: "Drive Type",
          },
          {
            fieldName: "engine_cylinders",
            fieldType: "text",
            fieldLable: "Engine Cylanders",
          },
          {
            fieldName: "doors",
            fieldType: "number",
            fieldLable: "Doors",
          },
          {
            fieldName: "engine_type",
            fieldType: "text",
            fieldLable: "Engine Type",
          },
          // {
          //   fieldName: "horse_power",
          //   fieldType: "text",
          //   fieldLable: "Horse Power",
          // },
          {
            fieldName: "engine_size",
            fieldType: "text",
            fieldLable: "Engin Size",
          },
          {
            fieldName: "trim",
            fieldType: "text",
            fieldLable: "Trim",
          },
          {
            fieldName: "fuel_type",
            fieldType: "text",
            fieldLable: "Fuel Type",
          },
          {
            fieldName: "fuel_capacity",
            fieldType: "text",
            fieldLable: "Fuel Capacity",
          },
          {
            fieldName: "high_msrp",
            fieldType: "text",
            fieldLable: "MRSP",
          },
        ],
      },
      {
        categoryName: "Customer",
        categoryItem: [
          {
            fieldName: "email",
            fieldType: "email",
            fieldLable: "E-mail",
          },
          {
            fieldName: "mobile",
            fieldType: "text",
            fieldLable: "Mobile",
          },
          {
            fieldName: "gender",
            fieldLable: "Gender",
            fieldType: "select",
            fieldOptions: [
              { value: "", label: "Select Gender" },
              { value: "0", label: "Male" },
              { value: "1", label: "Female" },
            ],
          },
          {
            fieldName: "f_name",
            fieldType: "text",
            fieldLable: "First Name",
          },
          {
            fieldName: "l_name",
            fieldType: "text",
            fieldLable: "Last Name",
          },
        ],
      },
      {
        categoryName: "Sell Data",
        categoryItem: [
          {
            fieldName: "sale_price",
            fieldType: "text",
            fieldLable: "Sale Price",
          },
          {
            fieldName: "waranty",
            fieldType: "text",
            fieldLable: "Warranty",
          },
        ],
      },
      {
        categoryName: "Finances",
        categoryItem: [
          {
            fieldName: "financeAmount",
            fieldType: "text",
            fieldLable: "Finance Amount",
          },
          { fieldName: "term", fieldType: "text", fieldLable: "Term" },
          {
            fieldName: "profit",
            fieldType: "text",
            fieldLable: "Profit",
          },
          {
            fieldName: "payments",
            fieldType: "text",
            fieldLable: "Payments",
          },
        ],
      },
    ];
  } else {
    return [
      {
        categoryName: "General Deal",
        categoryItem: [
          {
            fieldType: "select",
            fieldName: "lead_type",
            fieldLable: "Lead Type",
            fieldOptions: [
              { value: "", label: "Select lead type" },
              { value: "1", label: "Value Your Trade" },
              { value: "2", label: "Car Finder" },
              { value: "3", label: "Dealer Web Finances" },
              { value: "4", label: "Book Appointment" },
              { value: "5", label: "Contact us" },
              { value: "6", label: "Service Appointment" },
              { value: "7", label: "Order part(s)" },
              { value: "8", label: "E-Test Drive" },
              { value: "9", label: "Admin Lead" },
              { value: "10", label: "Test Drive" },
            ],
          },
          {
            fieldType: "select",
            fieldName: "negotiate_status",
            fieldLable: "Negotiate Status",
            fieldOptions: [
              { value: "", label: "Select negotiate status" },
              // { value: "-1", label: "Not Seen" },
              // { value: "0", label: "Seen" },
              { value: "1", label: "Qualified" },
              { value: "2", label: "Contact Made" },
              { value: "3", label: "Proposal(s)" },
              { value: "4", label: "Won" },
              { value: "5", label: "Lost" },
            ],
          },
          {
            fieldType: "select",
            fieldLable: "Customer Type",
            fieldName: "customer_type",
            fieldOptions: [
              { value: "", label: "Select Customer type" },
              { value: "1", label: "Person" },
              { value: "2", label: "Business" },
            ],
          },
          {
            fieldType: "number",
            fieldLable: "Success Forcast",
            fieldName: "success_forcast",
          },
          {
            fieldType: "text",
            fieldLable: "Dealership Comment",
            fieldName: "dealership_comment",
          },
        ],
      },
      {
        categoryName: "Vehicle on Deal",
        categoryItem: [
          // {
          //   fieldType: "select",
          //   fieldLable: "Dealership Opinion",
          //   fieldName: "dealership_opinion",
          //   fieldOptions: [
          //     { value: "1", label: "Pending" },
          //     { value: "2", label: "Accept" },
          //     { value: "3", label: "Reject" },
          //   ],
          // },
          {
            fieldName: "car_on_negotiate_status",
            fieldLable: "Car On Deal status",
            fieldType: "select",
            fieldOptions: [
              { value: "", label: "Select car on Deal" },
              { value: "1", label: "Negotiation Started" },
              { value: "2", label: "Bill Of Sales" },
              { value: "3", label: "Down Payment" },
              { value: "4", label: "Finances" },
              { value: "5", label: "Paid off" },
            ],
          },
          {
            fieldType: "number",
            fieldLable: "Success Forcaast",
            fieldName: "mid_success_forcast",
          },
        ],
      },
      {
        categoryName: "Inventory Advance",
        categoryItem: [
          {
            fieldName: "odometer",
            fieldLable: "Odometer",
            fieldType: "number",
          },
          {
            fieldName: "odometer_type",
            fieldLable: "Odometer Type",
            fieldType: "select",
            fieldOptions: [
              { value: "", label: "Select odometer type" },
              { value: "1", label: "Miles" },
              { value: "2", label: "Km" },
            ],
          },
          {
            fieldName: "vehicle_condition",
            fieldLable: "Vehicle Condition",
            fieldType: "select",
            fieldOptions: [
              { value: "", label: "Select Vehicle Condition" },
              { value: "1", label: "New" },
              { value: "2", label: "As New" },
              { value: "3", label: "Excelent" },
              { value: "4", label: "Good" },
              { value: "5", label: "Fair" },
              { value: "6", label: "Salvage" },
            ],
          },
          // {
          //   fieldName: "is_it_special",
          //   fieldLable: "Special",
          //   fieldType: "select",
          //   fieldOptions: [
          //     { value: "1", label: "to show in special cars show" },
          //     { value: "2", label: "not show" },
          //   ],
          // },
          {
            fieldName: "sell_price",
            fieldLable: "Sell Price",
            fieldType: "number",
          },
          {
            fieldName: "special_price",
            fieldLable: "Special Price",
            fieldType: "number",
          },
          {
            fieldName: "vehicle_status",
            fieldLable: "Vehicle Status",
            fieldType: "select",
            fieldOptions: [
              { value: "", label: "Select Vehicle Status" },
              { value: "1", label: "Just Entered" },
              { value: "2", label: "Need Service" },
              { value: "3", label: "At Service" },
              { value: "4", label: "Ready ForSale" },
              { value: "5", label: "Negotiation Started" },
              { value: "6", label: "Bill Of Sales" },
              { value: "7", label: "Down Payment" },
              { value: "8", label: "Sold" },
            ],
          },
        ],
      },
      {
        categoryName: "Inventory basic",
        fieldLable: "Inventory Basic",
        categoryItem: [
          {
            fieldName: "vin_number",
            fieldType: "text",
            fieldLable: "Vin Number",
          },
          {
            fieldName: "make",
            fieldType: "text",
            fieldLable: "Make",
          },
          {
            fieldName: "model",
            fieldType: "text",
            fieldLable: "Model",
          },
          {
            fieldName: "model_year",
            fieldType: "number",
            fieldLable: "Model Year",
          },
          {
            fieldName: "body_style",
            fieldType: "text",
            fieldLable: "Body Style",
          },
          {
            fieldName: "drive_type",
            fieldType: "text",
            fieldLable: "Drive Type",
          },
          {
            fieldName: "engine_cylinders",
            fieldType: "text",
            fieldLable: "Engine Cylanders",
          },
          {
            fieldName: "doors",
            fieldType: "number",
            fieldLable: "Doors",
          },
          {
            fieldName: "engine_type",
            fieldType: "text",
            fieldLable: "Engine Type",
          },
          // {
          //   fieldName: "horse_power",
          //   fieldType: "text",
          //   fieldLable: "Horse Power",
          // },
          {
            fieldName: "engine_size",
            fieldType: "text",
            fieldLable: "Engin Size",
          },
          {
            fieldName: "trim",
            fieldType: "text",
            fieldLable: "Trim",
          },
          {
            fieldName: "fuel_type",
            fieldType: "text",
            fieldLable: "Fuel Type",
          },
          {
            fieldName: "fuel_capacity",
            fieldType: "text",
            fieldLable: "Fuel Capacity",
          },
          {
            fieldName: "high_msrp",
            fieldType: "text",
            fieldLable: "MRSP",
          },
        ],
      },
      {
        categoryName: "Customer",
        categoryItem: [
          {
            fieldName: "email",
            fieldType: "email",
            fieldLable: "E-mail",
          },
          {
            fieldName: "mobile",
            fieldType: "text",
            fieldLable: "Mobile",
          },
          {
            fieldName: "gender",
            fieldLable: "Gender",
            fieldType: "select",
            fieldOptions: [
              { value: "", label: "Select Gender" },
              { value: "0", label: "Male" },
              { value: "1", label: "Female" },
            ],
          },
          {
            fieldName: "f_name",
            fieldType: "text",
            fieldLable: "First Name",
          },
          {
            fieldName: "l_name",
            fieldType: "text",
            fieldLable: "Last Name",
          },
        ],
      },
      {
        categoryName: "Sell Data",
        categoryItem: [
          {
            fieldName: "sale_price",
            fieldType: "text",
            fieldLable: "Sale Price",
          },
          {
            fieldName: "waranty",
            fieldType: "text",
            fieldLable: "Warranty",
          },
        ],
      },
      {
        categoryName: "Finances",
        categoryItem: [
          {
            fieldName: "financeAmount",
            fieldType: "text",
            fieldLable: "Finance Amount",
          },
          { fieldName: "term", fieldType: "text", fieldLable: "Term" },
          {
            fieldName: "profit",
            fieldType: "text",
            fieldLable: "Profit",
          },
          {
            fieldName: "payments",
            fieldType: "text",
            fieldLable: "Payments",
          },
        ],
      },
    ];
  }
};
export const SELLER_NEGOTIATE = [
  { value: 0, label: "Not Seen" },
  { value: 1, label: "Qualified" },
  { value: 2, label: "Contact Made" },
  { value: 3, label: "Proposal(s)" },
  { value: 4, label: "Won" },
  { value: 5, label: "Lost" },
];
