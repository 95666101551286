import React, { useState, useContext, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as INITIAL_VALUES from "../../constant/formik/initial_values";
import * as VALIDATION_SCHEMA from "../../constant/formik/validation_schema";
import { onSubmit } from "../../utils/submit/signin";
import { useHistory } from "react-router-dom";
import { useIsAuthDispatch, useIsAuthState } from "../../hooks/useAuth";
import {
  handleClose,
  handleShow,
} from "../../utils/forget-password/forget_password";
import ForgetPassword from "../layout/modals/forget_password";
import {
  FaEye,
  FaEyeSlash,
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaLock,
  FaTwitterSquare,
  FaUser,
} from "react-icons/fa";
import TrustedDevice from "../layout/modals/trusted_device";
import SimpleLoading from "../common/loading/simple_loading";
import { BASE_URL } from "../../constant/base";
import { SocketContext } from "../../context/socket"
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";


const DealerSignin = (props) => {
  const [loading, setLoading] = useState(false);
  const [seePass, setSeePass] = useState(false);
  const [seeCode, setSeeCode] = useState(false);
  const { chat } = useContext(SocketContext)
  const { setUserStatus, } = chat
  const auth = useIsAuthState();
  const history = useHistory();
  const [forgetPassword, setForgetPassword] = React.useState(false);
  const [trustedDevice, setTrustedDevice] = useState(false);
  const [retryAfter, setRetryAfter] = useState(localStorage.getItem("retryAfter") || 0);
  const [attempts, setAttempts] = useState(JSON.parse(localStorage.getItem("attempts")) || []);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const recaptchaRef = useRef();
  const recaptchaRefmobile = useRef();
  const [ShowStatus, setShowStatus] = useState(null);

  const handleShowForgetPassword = React.useCallback(
    () => handleShow(setForgetPassword),
    []
  );
  const handleCloseForgetPassword = React.useCallback(
    () => handleClose(setForgetPassword),
    []
  );
  const handleTrustedDeviceClose = () => {
    setTrustedDevice(false);
  };

  if (localStorage.getItem("isLoggedIn")) {
    if (auth.ds_status === 4) {
      history.replace("/admin/pricing");
    } else if (auth.ds_status === 8) {
      history.replace("/admin/pricing");
    } else if (
      auth.ds_status === 6 ||
      auth.ds_status === 3 ||
      auth.ds_status === 5 ||
      auth.ds_status === 7
    ) {
      history.replace("/admin");
    }
  }

  const setAuth = useIsAuthDispatch();
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.ceil(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  const formik = useFormik({
    initialValues: INITIAL_VALUES.SIGNIN_INITILVALUES,
    validationSchema: VALIDATION_SCHEMA.SIGNIN_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      setLoading(true);
      const currentTime = Date.now();

      const filteredAttempts = attempts.filter(attempt => currentTime - attempt < 2 * 60 * 1000);

      if (filteredAttempts.length >= 2) {
        setRetryAfter(2 * 60 * 1000 - (currentTime - filteredAttempts[0]));
        toast.warn(`Too many failed login attempts. Please try again later. ${formatTime(2 * 60 * 1000 - (currentTime - filteredAttempts[0]))}`);
        setLoading(false);
        return;
      }
      if (window.location.hostname !== 'localhost' && !recaptchaValue) {
        toast.error("Please complete the reCAPTCHA.");
        setLoading(false);
        return;
      }

      const { status, token } = await onSubmit({ ...values, recaptchaToken: recaptchaValue });
      setShowStatus(status)
      if (status === 200) {
        setUserStatus("online");
        setLoading(false);
        const _res = await fetch(`${BASE_URL}api/user/info`, {
          credentials: "include",
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        const _resJson = await _res.json();
        if (_res.status === 200) {
          setAuth({ isLoggedIn: true, ..._resJson });
          localStorage.setItem(
            "isLoggedIn",
            JSON.stringify({
              isLoggedIn: true,
              components: _resJson?.components,
              ds_status: _resJson?.ds_status,
              user: { frk_role: _resJson?.user?.frk_role },
            })
          );
          // Navigation based on ds_status
          if (_resJson.ds_status === 3) {
            history.replace("/admin");
          } else if (_resJson.ds_status === 4 || _resJson.ds_status === 8) {
            history.replace("/admin/pricing");
          } else {
            history.replace("/admin");
          }
          localStorage.removeItem("attempts");
          setAttempts([]);
        }
      } else if (status === 403) {
        setTrustedDevice(true);
        setLoading(false);
      } else if (status === 402) {
        localStorage.removeItem("attempts");
        values.code = "";
        setAttempts([]);
        setLoading(false);
      } else if (status === 409) {
        values.code = "";
        setLoading(false);
      } else if (status === 400) {
        values.code = "";
        setLoading(false);
      } else {
        history.replace("/signin");
        values.code = "";
        const value = [...filteredAttempts, currentTime];
        localStorage.setItem("attempts", JSON.stringify(value));
        setAttempts([...filteredAttempts, currentTime]);
        setLoading(false);
        setRecaptchaValue(null); // Reset reCAPTCHA value

        if (recaptchaRef.current) {
          recaptchaRef.current.reset(); // Reset reCAPTCHA widget
        }
        if (recaptchaRefmobile.current) {
          recaptchaRefmobile.current.reset(); // Reset reCAPTCHA widget
        }
      }
    },
  });

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };


  useEffect(() => {
    if (retryAfter > 0) {
      const timer = setInterval(() => {
        setRetryAfter(prevRetryAfter => {
          if (prevRetryAfter <= 1000) {
            clearInterval(timer);
            return 0;
          }
          return prevRetryAfter - 1000;
        });
      }, 1000);
      localStorage.setItem("retryAfter", retryAfter);

      return () => clearInterval(timer);
    }
  }, [retryAfter]);

  return (
    <>
      <div className=" p-0 m-0 h-100 signin_page_background d-flex flex-column align-items-center justify-content-between">
        <div className="p-0 m-0 d-flex flex-column align-items-center justify-content-center col-12 pt-md-5">
          <div className="p-2 m-0 col-10 col-md-8 col-lg-6 col-xl-4 signin_form_style rounded mt-2">
            <div className="d-flex justify-content-center align-items-center p-0 m-0">
              <img
                src="/images/HILLZ-sign-in.png"
                alt="hillzlogo"
                className="p-0 m-0 hillz_image_log_signin"
              // style={{ height: "200px", width: "300px" }}
              />
            </div>
            <div className="p-0 m-0">
              <form
                onSubmit={formik.handleSubmit}
                className="p-0 m-0 px-1 px-sm-4"
              >
                <div className="form-group p-0 m-0 mb-3">
                  <div className="form-control d-flex align-items-center bg-white rounded rounded-pill form_input-style">
                    <i
                      className="p-0 m-0 mx-2 edit_icon-style"
                      style={{ color: "#666666" }}
                    >
                      <FaUser />
                    </i>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      placeholder="Username"
                      className="border-0 bg-white bg-white w-100"
                      style={{ outline: "none" }}
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <i
                      className="p-0 m-0 mx-2 edit_icon-style"
                      style={{ color: "transparent" }}
                    >
                      <FaUser />
                    </i>
                  </div>
                  <div>
                    {formik.errors.username && formik.touched.username && (
                      <div className="mx-2" style={{ color: "#9d0208" }}>
                        {formik.errors.username}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group p-0 m-0 ">
                  <div className="form-control d-flex align-items-center bg-white rounded rounded-pill form_input-style">
                    <i
                      className="p-0 m-0 mx-2 edit_icon-style"
                      style={{ color: "#666666" }}
                    >
                      <FaLock />
                    </i>
                    <input
                      type={seePass ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Password"
                      style={{ outline: "none" }}
                      className="border-0 bg-white w-100"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <i
                      className="p-0 m-0 mx-2 edit_icon-style"
                      style={{ color: "#666666" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setSeePass((prev) => !prev);
                      }}
                    >
                      {seePass ? <FaEye /> : <FaEyeSlash />}
                    </i>
                  </div>
                  <div>
                    {formik.errors.password && formik.touched.password && (
                      <div className="mx-2" style={{ color: "#9d0208" }}>
                        {formik.errors.password}
                      </div>
                    )}
                  </div>
                </div>
                {
                  ShowStatus === 409 &&
                  <div className="form-group p-0 m-0 pt-3 ">
                    <div className="form-control d-flex align-items-center bg-white rounded rounded-pill form_input-style">
                      <i
                        className="p-0 m-0 mx-2 edit_icon-style"
                        style={{ color: "#666666" }}
                      >
                        <FaLock />
                      </i>
                      <input
                        type={seeCode ? "text" : "password"}
                        id="code"
                        name="code"
                        placeholder="Code"
                        style={{ outline: "none" }}
                        className="border-0 bg-white w-100"
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <i
                        className="p-0 m-0 mx-2 edit_icon-style"
                        style={{ color: "#666666" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setSeeCode((prev) => !prev);
                        }}
                      >
                        {seeCode ? <FaEye /> : <FaEyeSlash />}
                      </i>
                    </div>
                    {/* <div>
                    {formik.errors.password && formik.touched.password && (
                      <div className="mx-2" style={{ color: "#9d0208" }}>
                        {formik.errors.password}
                      </div>
                    )}
                  </div> */}
                  </div>
                }

                <div className="d-none d-md-flex justify-content-center pt-3">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LeqARUqAAAAAOHmsCWPnIgfDYusoT4Mdyh8oAWt"
                    onChange={handleRecaptchaChange}

                  />
                </div>
                <div className="d-flex d-md-none justify-content-center pt-3">
                  <ReCAPTCHA
                    ref={recaptchaRefmobile}
                    sitekey="6LeqARUqAAAAAOHmsCWPnIgfDYusoT4Mdyh8oAWt"
                    onChange={handleRecaptchaChange}
                    size="compact"
                  />
                </div>
                <div
                  className="p-0 m-0 my-2 col-12"
                  style={{ textAlign: "end" }}
                >
                  <strong
                    onClick={handleShowForgetPassword}
                    className="p-0 m-0"
                    style={{
                      color: "#666666",
                      cursor: "pointer",
                    }}
                  >
                    Forgot your password ?
                  </strong>
                </div>
                <div className="p-0 m-0 w-100 d-flex justify-content-center">
                  {loading ? (
                    <SimpleLoading />
                  ) : (
                    <button
                      type="submit"
                      className="btn form_submit-btn  col-12 shadow text-white mb-2"
                    >
                      Sign in
                    </button>
                  )}
                </div>
              </form>
            </div>
            <div className=" p-0 m-0"></div>
          </div>
          <div className="d-flex align-itmes-center justify-content-end">
            <a
              className="p-0 m-0"
              href="https://www.hillzdealer.com/packages"
              target="_blank"
              rel="noreferrer"
            >
              <button
                type="submit"
                className="btn px-5 shadow my-3 text-white rounded rounded-pill border border-white"
                // disabled
                style={{
                  backgroundColor: "transparent",
                  backdropFilter: "blur(40px)",
                }}
              // style={{
              //   color: "#219ebc",
              // }}
              >
                Sign up
              </button>
            </a>
          </div>
        </div>
        <div className="p-0 m-0  w-100 d-flex row align-items-center justify-content-between">
          <div className="p-0 m-0 col-12 col-md-3">
            <div className="m-0 p-0 d-flex align-items-center justify-content-center justify-content-sm-start">
              <div className="d-none d-sm-flex">
                <h3 className="text-white text-center px-3">Follow</h3>
              </div>
              <div
                className="p-0 m-0 px-2 mx-2 py-2 my-1 w-100 d-flex flex-column align-items-center justify-content-center social_signin_items_style"
                style={{ fontSize: "1.2rem" }}
              >
                <i
                  className="d-flex align-items-center justify-content-center text-white"
                  style={{ fontSize: "1.4rem" }}
                >
                  <FaFacebookSquare />
                </i>
                <span className="p-0 m-0 mx-2 span_display text-white">
                  Facebook
                </span>
              </div>
              <div
                className="p-0 m-0 px-2 mx-2 py-2 my-1 w-100 d-flex flex-column align-items-center justify-content-center social_signin_items_style"
                style={{ fontSize: "1.2rem" }}
              >
                <i
                  className="d-flex align-items-center justify-content-center text-white"
                  style={{ fontSize: "1.4rem" }}
                >
                  <FaTwitterSquare />
                </i>
                <span className="p-0 m-0 mx-2 span_display text-white">
                  Twitter
                </span>
              </div>
              <div
                className="p-0 m-0 px-2 mx-2 py-2 my-1 w-100 d-flex flex-column align-items-center justify-content-center social_signin_items_style"
                style={{ fontSize: "1.2rem" }}
              >
                <i
                  className="d-flex align-items-center justify-content-center text-white"
                  style={{ fontSize: "1.4rem" }}
                >
                  <FaInstagramSquare />
                </i>
                <span className="p-0 m-0 mx-2 span_display text-white">
                  Instagram
                </span>
              </div>
              <div
                className="p-0 m-0 px-2 mx-2 py-2 my-1 w-100 d-flex flex-column align-items-center justify-content-center social_signin_items_style"
                style={{ fontSize: "1.2rem" }}
              >
                <i
                  className="d-flex align-items-center justify-content-center text-white"
                  style={{ fontSize: "1.4rem" }}
                >
                  <FaLinkedin />
                </i>
                <span className="p-0 m-0 mx-2 span_display text-white">
                  Linkedin
                </span>
              </div>
            </div>
          </div>
          <div className="p-0 m-0 col-12 col-md-8 col-lg-6 col-xl-4">
            <div className="p-0 m-0 d-flex row align-items-center justify-content-center">
              <h6 className="text-white col-12 col-md-6 text-center text-md-end">
                Call : +18887254455
              </h6>
              <h6 className="text-white col-12 col-md-6 text-center text-md-start">
                E-mail : info@hillzdealer.com
              </h6>
            </div>
          </div>
        </div>
        <ForgetPassword
          show={forgetPassword}
          handleClose={handleCloseForgetPassword}
        />
        <TrustedDevice
          username={formik.values.username}
          show={trustedDevice}
          handleClose={handleTrustedDeviceClose}
        />
      </div>
    </>
  );
};
export default DealerSignin;
